import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  addToCart: [],
  isLoggedIn: false,
  thankYou: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = action.payload.user_role.toLowerCase() === 'ntn';
    },
    setAddToCart: (state, action) => {
      state.addToCart = action.payload;
    },
    logout: (state) => {
      state.user = {};
      state.isLoggedIn = false;
    },
    setThankYou: (state, action) => {
      state.thankYou = action.payload;
    },
  },
});

export const { setUser, logout, setAddToCart, setThankYou } = userSlice.actions;

export default userSlice.reducer;
