import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import { setAppointment } from '../../../redux-store/home/homeReducer';
import { Btn, MainContainer } from '../../style/StyleComponent';
import Appointment from '../appointment/Appointment';
import './NavHeader.css';

const NavHeader = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector((state) => state.users.user);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);
  return (
    <>
      <MainContainer
        id='navHeaderContainer'
        bgColor='#373f68'
        flexDirection='row'
        paddingVertical='15px'
        justifyContent='space-between'
        alignItem='center'
      >
        <div id='menuIcon' onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={faBars} color='#fcc028' />
        </div>
        <Link id='logoHead' to='/'>
          <img src={Logo} alt='Logo' />
        </Link>
        {width > 550 || isOpen ? (
          <div className='navItemsContainer'>
            <Link to={'/about-us'} className='navBtn'>
              About Us
            </Link>
            <Link to='/services' className='navBtn'>
              Services
            </Link>
            <div>
              <div onClick={handleClick} className='navBtn'>
                Tools & Resources
              </div>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigator('/salary-tax-calculator');
                    handleClose();
                  }}
                >
                  Salary-calculator
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>NTN-status</MenuItem> */}
                <MenuItem
                  onClick={() => {
                    navigator('/login');
                    handleClose();
                  }}
                >
                  Tax Portal
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigator('/blogs');
                    handleClose();
                  }}
                >
                  Blog
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigator('/research-and-insight');
                    handleClose();
                  }}
                >
                  Research & Insight
                </MenuItem>
              </Menu>
            </div>
           <Link
              to={
                user?.user_role === 'ntn' ? '/fileYourTaxReturn' : '/login'
              }
              className='navBtn'
            >
              TaxPortal
            </Link>

            <Link to='/overseas-chapter' className='navBtn'>
              Overseas Chapter
            </Link>
            <Link to='/contact' className='navBtn'>
              Contact
            </Link>
          </div>
        ) : null}
        <Btn
          id='appointmentBtn'
          width='fit-content'
          fontSize='16px'
          onClick={() => {
            dispatch(setAppointment());
          }}
        >
          Appointment
        </Btn>
      </MainContainer>
      <Appointment />
    </>
  );
};

export default NavHeader;
